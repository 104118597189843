/* eslint-disable react-hooks/exhaustive-deps */
import { Box, OutlinedInput, SelectChangeEvent, Typography } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';
import React, { useContext } from 'react';
import CustomMultiSelect from 'src/components/atoms/CustomMultiSelect';
import { CustomMenuItem, CustomSelect } from 'src/components/atoms/CustomSelect';
import {
  DELEGATE_VIEW_MODE,
  INVESTER_TYPE,
  ViewModeOptions,
} from 'src/modules/users-management/consts';
import { InvestorViewModeContext, ViewMode } from 'src/providers/InvestorViewModeProvider';

const InvestorHeaderFilter = () => {
  const { viewMode, onChangeViewMode, onChangeInvestmentEntities, entities, investmentEntities } =
    useContext(InvestorViewModeContext);

  const onSwitchViewMode = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    onChangeViewMode(value as ViewMode);
  };

  const handleChangeInvestmentEntities = (values: string[] | string) => {
    onChangeInvestmentEntities(values);
  };

  const handleChangeDelegateInvestmentEntity = (event: any) => {
    onChangeInvestmentEntities(event?.target?.value);
  };

  const investmentEntitiesOptions = React.useMemo(() => {
    return (entities?.[viewMode] || [])
      .map((entity) => ({
        label: entity.name,
        value: entity.id,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label));
  }, [entities, viewMode]);

  const viewModeOptions = React.useMemo(() => {
    return (ViewModeOptions || []).filter((x) =>
      _isEmpty(entities?.[DELEGATE_VIEW_MODE]) ? x.value !== DELEGATE_VIEW_MODE : x,
    );
  }, [entities]);

  return (
    <Box className='flex'>
      <Box className='flex items-center ' marginRight={'12px'}>
        <Typography fontSize={14} variant='body1' color='neutral.ne500' mr='8px'>
          View Mode
        </Typography>
        <CustomSelect
          value={viewMode}
          onChange={onSwitchViewMode}
          className='h-[32px] w-[150px] text-[14px]'
          IconComponent={() => (
            <span className='material-symbols-rounded mr-4 arrow-icon'>expand_more</span>
          )}
          defaultValue={INVESTER_TYPE}
        >
          {viewModeOptions.map((option) => (
            <CustomMenuItem key={option.value} value={option.value}>
              {option.label}
            </CustomMenuItem>
          ))}
        </CustomSelect>
      </Box>
      {viewMode === DELEGATE_VIEW_MODE ? (
        <Box className='flex items-center'>
          <Box
            className='flex items-center justify-center border border-e-0 rounded-s-lg min-w-fit h-[32px] py-[11px] px-4'
            bgcolor='neutral.ne100'
            borderColor='neutral.ne400'
          >
            <Typography variant='body3' color='neutral.ne600'>
              Investment Entity
            </Typography>
          </Box>
          <CustomSelect
            onChange={handleChangeDelegateInvestmentEntity}
            value={investmentEntities}
            input={
              <OutlinedInput
                className='h-[32px] w-[200px] rounded-e-lg'
                sx={{
                  borderRadius: '0px',
                }}
              />
            }
            MenuProps={{
              sx: {
                height: 300,
              },
              PaperProps: {
                sx: {
                  maxWidth: 300,
                },
              },
            }}
          >
            {investmentEntitiesOptions.map((option: { value: string; label: string }) => (
              <CustomMenuItem
                className='whitespace-normal'
                key={option.value}
                value={option.value}
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                {option.label}
              </CustomMenuItem>
            ))}
          </CustomSelect>
        </Box>
      ) : (
        <CustomMultiSelect
          seletedItem={investmentEntities}
          label='Investment Entity'
          options={investmentEntitiesOptions}
          onChange={handleChangeInvestmentEntities}
          customSx={{
            height: '32px !important',
            fontSize: 14,
          }}
          shouldSelectAll={investmentEntities?.length === 0}
        />
      )}
    </Box>
  );
};

export default InvestorHeaderFilter;
