/* eslint-disable autofix/no-unused-vars */
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { DocsIcon } from 'src/assets/icons/DocsIcon';
import { PDFIcon } from 'src/assets/icons/PDFIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import UploadFilesIcon from 'src/assets/icons/UploadFilesIcon';
import RectangleLogoDefault from 'src/assets/images/RectangleLogoDefault';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomTable from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect, { IFormSelectOption } from 'src/components/atoms/FormSelect';
import UploadButton from 'src/components/atoms/UploadButton';
import ImageCropper from 'src/components/molecules/ImageCropper';
import { LogoSetting } from 'src/components/molecules/organisation-settings';
import {
  BRANDING_TYPE,
  DOCUMENT_ACCEPT_TYPE,
  DOCUMENT_TYPE,
  DocumentTypeOptions,
  MAX_DOCUMENT_SIZE,
  MAX_IMAGE_SIZE,
} from 'src/constants/common';
import { OFFER_BANNER_ASPECT_RATIO } from 'src/constants/offer';
import { showToast } from 'src/helpers/toast';
import { useUploadDocument, useUploadImage } from 'src/modules/common/hooks';
import { IUploadFile } from 'src/modules/common/type';
import { useDeleteFundBrandingDocument } from 'src/modules/funds-management/hooks';
import {
  IBrandingDetail,
  IDocument,
  ISignatoryContact,
  IUpsertFundForm,
} from 'src/modules/funds-management/type';
import { handleErrorFromServer } from 'src/utils/common';
import CreateSignatoryContactUser, {
  CreateSignatoryContactUserHandler,
} from './CreateSignatoryContactUser';

interface IFundOverviewAndBrandingProps extends IUpsertFundForm {
  fundContacts?: ISignatoryContact[];
  fundId: string;
}

interface ITableData {
  id?: string;
  fileName: string;
  documentType: number;
  path: string;
  fileSize: number;
  otherDescription?: string;
}

const RectangleLogo = {
  logoWidth: '229px',
  logoHeight: '127px',
  defaultLogo: <RectangleLogoDefault />,
  ratio: [6, 6],
  sx: { p: 0, backgroundColor: 'unset' },
  isOptional: true,
  titleClass: 'text-[16px] font-[500]',
};

const FundOverviewAndBranding: FC<IFundOverviewAndBrandingProps> = ({
  fundContacts,
  isViewMode,
  fundId,
}) => {
  const reviewBannerRef = useRef<IBasicModalElement>(null);
  const createUserRef = useRef<CreateSignatoryContactUserHandler>(null);
  const createDrawerRef = useRef<ICustomDrawerElement>(null);
  const [banner, setBanner] = useState<{ name?: string; url: string }>();
  const [viewBanner, setViewBanner] = useState<boolean>(false);

  const { mutate: uploadImageMutate, isLoading: isUploadImageLoading } = useUploadImage();
  const { mutate: uploadDocumentMutate, isLoading: isUploadDocLoading } = useUploadDocument();
  const { mutate: deleteDocumentMutate, isLoading: isDeleteDocLoading } =
    useDeleteFundBrandingDocument();

  const form = useFormContext();

  const { append, update } = useFieldArray({
    control: form.control,
    name: 'brandingDetails',
  });
  const { append: appendDocument, remove: removeDocument } = useFieldArray({
    control: form.control,
    name: 'documents',
  });

  const watchSignatoryPerson = form.watch('signatoryPerson') || [];
  const watchBrandingDetails = form.watch('brandingDetails') || [];
  const watchDocumentType = form.watch('documentType');
  const watchDocuments = form.watch('documents');
  const watchOtherDescription = form.watch('otherDescription');
  const watchFundContactId = form.watch('fundContactId');

  const isDisabledAddNewSignatoryPerson = !isEmpty(watchFundContactId);
  const isDisabledUploadFilesBtn =
    isViewMode || !Object.values(DOCUMENT_TYPE).includes(watchDocumentType);

  const handleCreateNewUser = () => {
    createUserRef.current?.onCreate(handleAddUser);
  };

  const handleAddUser = (newPerson: ISignatoryContact) => {
    newPerson.id = `new_${Date.now().toString()}`;
    form.setValue('signatoryPerson', newPerson, { shouldDirty: true });
    form.setValue('fundContactId', newPerson.id);
    createDrawerRef.current?.close();
  };

  const handleChangeBanner = (file: File) => {
    if (file) {
      const fileSizeKiloBytes = file.size / 1024;
      if (fileSizeKiloBytes > MAX_IMAGE_SIZE) {
        showToast('File size is greater than maximum limit', 'error');
      } else {
        const bannerUrl = URL.createObjectURL(file);
        const bannerImg = {
          name: file.name,
          url: bannerUrl,
        };
        setBanner(bannerImg);
        reviewBannerRef.current?.open();
      }
    }
  };

  const handleChangeImage = (file: File, brandingType: number) => {
    if (file) {
      const fileSizeKiloBytes = file.size / 1024;
      if (fileSizeKiloBytes > MAX_IMAGE_SIZE) {
        showToast('File size is greater than maximum limit', 'error');
      } else {
        uploadImageMutate(file, {
          onSuccess: (image: IUploadFile) => {
            const imgUrl = URL.createObjectURL(file);
            const fileIndex = watchBrandingDetails.findIndex(
              (f: IBrandingDetail) => f.brandingType === brandingType,
            );
            const params = {
              ...image,
              brandingType,
              imgUrl,
              isNew: true,
            };
            fileIndex !== -1 ? update(fileIndex, params) : append(params);
          },
          onError: handleErrorFromServer,
        });
      }
    }
  };

  const handleChangeDocument = (files: FileList) => {
    const file = files[0];
    if (!file || !Number.isInteger(watchDocumentType) || watchDocumentType == undefined) return;
    uploadDocumentMutate(file, {
      onSuccess: (image: IDocument) => {
        const newDocument = {
          ...image,
          documentType: watchDocumentType,
          isNew: true,
        };
        if (watchDocumentType === DOCUMENT_TYPE.Other && watchOtherDescription) {
          newDocument.otherDescription = watchOtherDescription;
        }
        appendDocument(newDocument);
        showToast('File uploaded successfully', 'success');
      },
      onError: handleErrorFromServer,
    });
  };

  const handleCroppedOk = (file: File) => {
    uploadImageMutate(file, {
      onSuccess: (image: IUploadFile) => {
        const imgUrl = URL.createObjectURL(file);
        const fileIndex = watchBrandingDetails.findIndex(
          (f: IBrandingDetail) => f.brandingType === BRANDING_TYPE.Banner,
        );
        const params = {
          ...image,
          brandingType: BRANDING_TYPE.Banner,
          imgUrl,
          isNew: true,
        };
        fileIndex !== -1 ? update(fileIndex, params) : append(params);
        setBanner({ ...banner, url: imgUrl });
        reviewBannerRef.current?.close();
      },
      onError: handleErrorFromServer,
    });
  };

  const handleCroppedCancel = () => {
    viewBanner && setViewBanner(!viewBanner);
    reviewBannerRef.current?.close();
  };

  const handleDeleteDocument = (row: ITableData) => {
    const rowIndex = (watchDocuments || []).findIndex((f: ITableData) => f.path === row.path);
    if (rowIndex !== -1) {
      if (row.id) {
        deleteDocumentMutate(
          { fundId, docId: row.id },
          {
            onSuccess: () => {
              removeDocument(rowIndex);
            },
            onError: handleErrorFromServer,
          },
        );
      } else {
        removeDocument(rowIndex);
      }
    }
  };

  const handleDeleteSelectedSignatoryPerson = (option?: IFormSelectOption) => {
    if (option?.value === watchSignatoryPerson?.id) {
      form.setValue('signatoryPerson', '');
    }
    form.setValue('fundContactId', '');
  };

  const signatoryOptions = useMemo(() => {
    let options = (fundContacts || []).map((item: ISignatoryContact) => ({
      value: item.id || '',
      label: `${item.firstName} ${item.lastName} | ${item.email}`,
    }));
    if (!isEmpty(watchSignatoryPerson)) {
      options = options.concat({
        value: watchSignatoryPerson.id || '',
        label: `${watchSignatoryPerson.firstName} ${watchSignatoryPerson.lastName} | ${watchSignatoryPerson.email}`,
      });
    }
    return options;
  }, [fundContacts, watchSignatoryPerson]);

  const getImageByType = useCallback(
    (imageType: number) => {
      const image = (watchBrandingDetails || []).find(
        (f: IBrandingDetail) => f.brandingType === imageType,
      );
      return image?.imgUrl || '';
    },
    [watchBrandingDetails],
  );

  const columns = [
    {
      title: 'File name',
      key: 'fileName',
      renderNode: (row: ITableData) => {
        const docType = row.fileName.split('.').pop();
        const fileSizeInMB = (row.fileSize / (1024 * 1024)).toFixed(1);
        return (
          <Box className='flex items-center'>
            {docType === 'pdf' ? <PDFIcon /> : <DocsIcon />}
            <Typography variant='body2' mx='8px' color='base.black'>
              {row.fileName}
            </Typography>
            <Typography color='neutral.ne800' variant='body3'>
              {`${fileSizeInMB}MB`}
            </Typography>
          </Box>
        );
      },
      sx: { width: '50%', borderBottomWidth: '1px !important' },
    },
    {
      title: 'Document type',
      key: 'documentType',
      renderNode: (row: ITableData) => {
        const document = DocumentTypeOptions.find((f) => f.value === row.documentType);
        return (
          <Box className='flex justify-between items-center'>
            {document?.value === DOCUMENT_TYPE.Other ? row.otherDescription : document?.label}
            <IconButton
              disabled={isDeleteDocLoading || isUploadDocLoading}
              onClick={() => handleDeleteDocument(row)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
      sx: { width: '50%', borderBottomWidth: '1px !important' },
    },
  ];

  return (
    <Box>
      <Box p='40px' bgcolor='neutral.ne100' className='rounded-[10px]'>
        <LogoSetting
          {...RectangleLogo}
          description='.png, .jpeg, files up to 10MB. The recommended ratio size is 16:9. This image should have a transparent background.'
          title='Fund Logo'
          onChange={(file) => handleChangeImage(file, BRANDING_TYPE.Logo)}
          logo={getImageByType(BRANDING_TYPE.Logo)}
          readOnly={isViewMode}
        />
        <LogoSetting
          {...RectangleLogo}
          title='Fund Banner'
          description='.png, .jpeg, files up to 10MB.
        The recommended size is 1080x300px.'
          sx={{
            ...RectangleLogo.sx,
            mt: '32px',
          }}
          onChange={handleChangeBanner}
          logo={getImageByType(BRANDING_TYPE.Banner)}
          readOnly={isViewMode}
        />
        <Box className={getImageByType(BRANDING_TYPE.Banner) ? 'flex mt-1' : 'hidden'}>
          <Typography variant='body3' color='neutral.ne500'>
            This image will be use for thumbnail -
          </Typography>
          <Typography
            onClick={() => {
              reviewBannerRef.current?.open();
              setViewBanner(true);
            }}
            className='ml-[6px] cursor-pointer'
            fontWeight={500}
            variant='body3'
            color='primary.main'
          >
            View
          </Typography>
        </Box>
        <Box my='32px'>
          <FormInput
            name='description'
            label='Description'
            optional='Optional'
            placeholder='Enter fund description'
            labelClass='text-[16px] font-[500]'
            disabled={isViewMode}
            maxLength={280}
          />
        </Box>
        <FormInput
          name='overview'
          label='Fund Overview'
          placeholder='Enter fund overview'
          labelClass='text-[16px] font-[500]'
          multiline
          rows={7}
          disabled={isViewMode}
          maxLength={1000}
          isShowCharactersLeft
        />
        <Box mt='32px'>
          <FormInput
            name='correspondenceDisclaimer'
            label='Correspondence Disclaimer'
            placeholder='Enter correspondence disclaimer'
            labelClass='text-[16px] font-[500]'
            multiline
            rows={5}
            disabled={isViewMode}
            maxLength={480}
            isShowCharactersLeft
          />
        </Box>
      </Box>
      <Typography variant='body2' fontWeight={500} my='32px'>
        Fund Documents
      </Typography>
      <Grid container mb='24px' spacing={2}>
        <Grid item xs={4} className='pt-[0]'>
          <FormSelect
            placeholder='Select type'
            name='documentType'
            options={DocumentTypeOptions}
            label='Select Type'
            disabled={isViewMode}
          />
        </Grid>
        {watchDocumentType === DOCUMENT_TYPE.Other && (
          <Grid item xs={4} className='pt-[0]'>
            <FormInput
              name='otherDescription'
              label='Description'
              disabled={isViewMode}
              placeholder='Enter description'
            />
          </Grid>
        )}
        <Grid item xs={4} className='flex justify-start items-end mb-[2px] pt-[0]'>
          <UploadButton
            label='Upload Files'
            startIcon={<UploadFilesIcon disabled={isDisabledUploadFilesBtn} />}
            inputProps={{
              accept: DOCUMENT_ACCEPT_TYPE,
            }}
            disabled={isDisabledUploadFilesBtn}
            validate={{ fileSize: MAX_DOCUMENT_SIZE }}
            handleFileChange={handleChangeDocument}
          />
        </Grid>
        <Grid item xs={12} className='pt-[0]'>
          <Typography variant='body3' color='neutral.ne500'>
            pdf, doc files - 20 MB max file size.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant='body3'>{`All Files (${watchDocuments.length})`}</Typography>
      <CustomTableContainer mt={1}>
        <CustomTable<ITableData>
          rows={watchDocuments || []}
          columns={columns}
          hasPagination={false}
          stickyHeader
          displayEmpty
          emptyDescription='No document.'
        />
      </CustomTableContainer>
      <Box my='32px'>
        <FormSelect
          placeholder='Select signatory'
          name='fundContactId'
          labelClass='text-[16px] font-[500]'
          options={signatoryOptions}
          label='Select signatory'
          tooltip='Details will be displayed on all formal correspondence.'
          onDelete={handleDeleteSelectedSignatoryPerson}
          disabled={isViewMode}
        />
        <CustomButton
          onClick={() => createDrawerRef.current?.open()}
          className='mt-[14px]'
          variant='text'
          startIcon={<PlusPrimaryIcon disabled={isDisabledAddNewSignatoryPerson} />}
          disabled={isViewMode || isDisabledAddNewSignatoryPerson}
        >
          <Typography
            variant='body2'
            fontWeight={600}
            color={isDisabledAddNewSignatoryPerson ? 'neutral.ne400' : 'primary.main'}
          >
            Add a signatory person
          </Typography>
        </CustomButton>
      </Box>
      <Box p='40px' bgcolor='neutral.ne100' className='rounded-[10px]'>
        <LogoSetting
          sx={RectangleLogo.sx}
          titleClass='text-[16px] font-[500]'
          title='Signature'
          description='.png, .jpeg, files up to 8MB.'
          logoHeight='170px'
          logoWidth='170px'
          isOptional
          ratio={[5, 7]}
          onChange={(file) => handleChangeImage(file, BRANDING_TYPE.Signature)}
          logo={getImageByType(BRANDING_TYPE.Signature)}
          readOnly={isViewMode}
        />
      </Box>
      <ImageCropper
        ref={reviewBannerRef}
        imgUrl={viewBanner ? getImageByType(BRANDING_TYPE.Banner) : banner?.url}
        imageName={banner?.name}
        title='Preview'
        onOk={handleCroppedOk}
        onCancel={handleCroppedCancel}
        viewOnly={viewBanner}
        isLoading={isUploadImageLoading}
        aspect={OFFER_BANNER_ASPECT_RATIO}
      />
      <CustomDrawer
        ref={createDrawerRef}
        title='Create New User'
        ButtonComponents={
          <Box className='flex gap-2'>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => createDrawerRef.current?.close()}
            >
              Cancel
            </CustomButton>
            <CustomButton className='w-[160px]' onClick={handleCreateNewUser}>
              Create new
            </CustomButton>
          </Box>
        }
      >
        <CreateSignatoryContactUser ref={createUserRef} />
      </CustomDrawer>
    </Box>
  );
};

export default FundOverviewAndBranding;
